import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Box, Section, Title } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import { Pages } from '../components/ContentfulContents/contentfulPages';
import ReactHtmlParser from 'react-html-parser';
import CTA from '../sections/landing1/CTA';

const Contact = () => {
  const { contact } = Pages();

  const {
    node: {
      copy: {
        childMarkdownRemark: { html: contactCopy },
      },
    },
  } = contact[0];

  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <Container>
            {contact[0]?.node?.sectionTitle && (
              <>
                <Title variant="card" key={`${contact[0]?.node?.id}${contact[0]?.node?.sectionTitle}`}>
                  {contact[0]?.node?.sectionTitle}
                </Title>
              </>
            )}
            <Row className="align-items-center">
              {contactCopy && (
                <>
                  <span>{ReactHtmlParser(contactCopy)}</span>
                </>
              )}
              {contact[0]?.node?.copy2?.childMarkdownRemark?.html && (
                <>
                  <span>{ReactHtmlParser(contact[0]?.node?.copy2?.childMarkdownRemark?.html)}</span>
                </>
              )}
              <Box mt={5} />

              {contact[0]?.node?.bulletPoints?.childMarkdownRemark?.html && (
                <>{ReactHtmlParser(contact[0]?.node?.bulletPoints?.childMarkdownRemark?.html)}</>
              )}
              <Col lg="6"></Col>
              <Col lg={5} className="offset-lg-1 order-lg-2 mt-5 mt-lg-0 pt-lg-5"></Col>
            </Row>
          </Container>
        </Section>
        <CTA />
      </PageWrapper>
    </>
  );
};
export default Contact;
